<template>
  <div class="addNoticeBox">
    <el-dialog :visible.sync="dialogVisible" top="80px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false":close-on-press-escape="false">
      <div class="addNoticeContent">
        <div class="addNoticeTitle">创建公告</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="公告标题" prop="title">
            <el-input :disabled="seeFlag" v-model="ruleForm.title" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item class="contentBox" label="公告正文" prop="content">
            <div class="editorBox">
            <quill-editor
              :disabled="seeFlag"
              class="editorContent"
              v-model="ruleForm.content"
              ref="myQuillEditor"
              :options="editorOption"
            />
          </div>
          </el-form-item>
          <div class="width-100 noticeUploadBox">
            <el-form-item label="公告附件">
                <div class="fileListBox" v-for="(item,index) in fileList">
                  <el-input  placeholder="请输入内容" readonly v-model="item.file1">
                    <template slot="prepend">附件{{index+1}}</template>
                    <el-button slot="append" :disabled="seeFlag" size="small" @click="delelteUploadUrl(index,item.id)" type="primary">X</el-button>
                  </el-input>
                  <el-upload
                    :disabled="(!!item.file1)&&seeFlag"
                    class="upload-demo"
                    action="string"
                    :on-change="(file,fileList)=>{fileChange(file,fileList,index)}"
                    :auto-upload="false">
                    <el-button class="uploadBtn" type="primary">选择文件</el-button>
                  </el-upload>
                  <div class="addUploadBox">
                    <div class="adduploadSty" v-if="!seeFlag" @click="addUpload">添加</div>
                    <div class="deleteuploadSty"  @click="deleteUpload(index,item.id)" v-if="(index!=0)&&(!seeFlag)">删除</div>
                  </div>
                </div>
            </el-form-item>
          </div>
        </el-form>

      </div>
      <div class="closeBox">
        <el-button  class="preservationBtn" v-if="!seeFlag" :loading="loading" @click="preservationDia(1)">保存</el-button>
        <el-button type="danger" class="closeBtn" v-if="!seeFlag" :loading="loading" @click="preservationDia(0)">发布</el-button>
        <el-button style="width:100px" @click="handleClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {insertNotice,getNoticeById,updateNotice,deleteNoticeFileById} from '@/api/noticeInformation/noticeInformation'
export default {
  model:{
    prop:'dialogVisible',
    event:'change'
  },
  props:['dialogVisible','type','id'],
  data() {
    return {
      input:'',
      content:'',
      ruleForm:{
        title:'',
        content:''
      },
      rules:{
        title:{ required: true, message: '请输入标题', trigger: 'blur' },
        content:{ required: true, message: '请输入内容', trigger: 'blur' },
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['formula', 'clean'],
            ['blockquote', 'code-block'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
          ]
        },
        placeholder: ''
      },
      fileList:[{}],
      loading:false,
      seeFlag:false
    }
  },
  mounted() {
    if(this.type == 'edit'||this.type == 'see'){
      this.getDetails()
    }
    if(this.type == 'see'){
      this.seeFlag = true;
    }
  },
  methods: {
    getDetails(){
      getNoticeById({id:this.id}).then(res=>{
        if(res.status == 200){
          this.ruleForm = {
            title:res.data.title,
            content:res.data.content
          }
          if(res.data.adjunctList){
            this.fileList = res.data.adjunctList.map(item=>{
              return {
                url:item.path,
                file1:item.name,
                id:item.id
              }
            })
          }
        }
      })
    },
    // 上传
    fileChange(file,fileList,i){
      this.fileList = this.fileList.map((item,index)=>{
        if(i==index){
          item.file = file.raw;
          item.url = URL.createObjectURL(file.raw);
          item.file1 = file.name
        }
        return item
      })
    },
    // 新增上传
    addUpload(){
      if(this.fileList.length>2){
        this.$message.error('最多上传3个')
        return
      }
      this.fileList.push({})
    },
    // 删除
    deleteUpload(i,id){
      this.fileList = this.fileList.filter((item,index)=>{
        return index!=i;
      })
      if(id){
        deleteNoticeFileById({fileId:id}).then(res=>{
          if(res.status == 200){
            this.$message.success('删除成功')
          }
        })
      }
    },
    // 上传url
    delelteUploadUrl(i,id){
      this.fileList = this.fileList.map((item,index)=>{
        if(index==i){
          return {}
        }
        return item
      })
      if(id){
        deleteNoticeFileById({fileId:id}).then(res=>{
          if(res.status == 200){
            this.$message.success('删除成功')
          }
        })
      }
    },
    // 保存
    preservationDia(state){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for (const key in this.ruleForm) {
            if (Object.hasOwnProperty.call(this.ruleForm, key)) {
              const item = this.ruleForm[key];
              formData.append(key,item)
            }
          }
          
          this.fileList.forEach((item,i)=>{
            if(item.file){
              formData.append('file', item.file);
            }
          })
          formData.append('state',state)
          this.loading = true;
          let fn;
          if(this.type=='add'){
            fn = insertNotice
          }else if(this.type=='edit'){
            fn = updateNotice
            formData.append('id',this.id)
          }
          fn(formData).then(res=>{
            if(res.status == 200){
              this.$emit('getData')
              this.handleClose()
            }else{
              this.$message.error(res.message)
            }
            this.loading =false;
          }).catch(error=>{
            this.loading =false;
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  },
}
</script>
<style lang="scss" scoped>
  .addNoticeBox{
    .el-upload-list{
      display: none;
    }
    .addNoticeContent{
      color: #000;
      padding: 0 25px;
      .addNoticeTitle{
        font-weight: bold;
        color: #000;
        font-size: 16px;
        margin-bottom: 20px;
      }
      
      // .inputTitle{
      //   display: flex;
      //   margin-top: 20px;
      //   p{
      //     width: 80px;
      //     line-height: 40px;
      //     &::before{
      //       content: "*";
      //       color: #f56c6c;
      //       margin-right: 4px;
      //     }
      //   }
      // }
      .editorBox{
        height: 300px;
        width: 100%;
        .editorContent{
          height: 100%;
        }
      }

      .noticeUploadBox{
        margin-top: 120px;
        .addUploadBox{
          display: flex;
          width: 110px;
          margin-left: 10px;
          .adduploadSty,.deleteuploadSty{
            width: 30px;
            text-align: center;
            cursor: pointer;
            color: rgb(45, 163, 254);
          }
          .deleteuploadSty{
            margin-left: 10px;
          }
        }
        .fileListBox{
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .uploadBtn{
            border: none;
            margin-left: 10px;
            background: #BD192C;
          }
        }
      }
    }

    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;

      .closeBtn{
        width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
      .preservationBtn{
        border: 1px solid #BD192C;
        color: #BD192C;
         width: 100px;
      }
    }
  }
</style>