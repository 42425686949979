<template>
  <div class="noticeInformationBox">
    <div class="searchBox">
      <div class="searchLeft">
        <ul>
          <li>
            <p class="searchTitle">公告标题：</p>
            <el-input v-model="title" placeholder="请输入公告标题"></el-input>
          </li>
          <li>
            <p class="searchStatus">状态：</p>
            <el-select v-model="state" placeholder="选择状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-button class="searchBtn" type="warning" @click="searchFun">查询</el-button>
          </li>
        </ul>
      </div>

      <div class="searchRight">
        <el-button class="addBtn" @click="addNoticeFun">创建公告</el-button>
      </div>
    </div>

    <div class="noticeInformationContent">
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
          <el-table-column align="center" prop="title" label="公告标题" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="160"></el-table-column>
          <el-table-column align="center" prop="createName" label="创建人" width="110"></el-table-column>
          <el-table-column align="center" :formatter="formatterFun" prop="date" label="状态" width="100"></el-table-column>
          <el-table-column align="center" label="操作" width="130">
            <template slot-scope="scope">
              <div class="signUpFlex">
                <p class="signUpColor" @click="seeFun(scope.row.id)" v-if="scope.row.state == 0">查看</p>
                <p class="signUpColor" @click="revokeFun(scope.row.id)" v-if="scope.row.state == 0">撤销</p>
                <p class="signUpColor" @click="editFun(scope.row.id)" v-if="scope.row.state == 1">编辑</p>
                <p class="signUpColor" @click="deleteFun(scope.row.id)" v-if="scope.row.state == 1">删除</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          layout="prev, pager, next"
          :page-size="10"
          :current-page="page"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <addNotice v-if="dialogVisible" :id="id" :type="type" @getData="getData" v-model="dialogVisible" />
  </div>
</template>
<script>
import addNotice from '@/views/noticeInformation/addNotice.vue';
import {listNoticeByPage,deleteNotice,cxNotice} from '@/api/noticeInformation/noticeInformation'
export default {
  components:{
    addNotice
  },
  data() {
    return {
      title:'',
      dialogVisible:false,
      type:'add',
      options: [
        {
          value: '',
          label: '不限状态'
        },
        {
          value: '1',
          label: '未发布'
        },
        {
          value: '0',
          label: '已发布'
        }
      ],
      state: '',
      id:'',
      total:0,
      page:1,
      tableData:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    searchFun(){
      this.page = 1;
      this.getData()
    },
    getData(){
      let data = {
        page:this.page,
        limit:10,
        title:this.title,
        state:this.state
      }

      listNoticeByPage(data).then(res=>{
        if(res.status == 200){
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }
      })
    },
    // 创建公告
    addNoticeFun(){
      this.type = 'add'
      this.dialogVisible = true;
    },
    // 查看
    seeFun(id){
      this.id = id;
      this.type = 'see';
      this.dialogVisible = true;
    },
    // 撤销
    revokeFun(id){
      this.$confirm('你确认要将该公告撤销发布吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cxNotice({id}).then(res=>{
          if(res.status == 200){
            this.$message.success('撤销成功！')
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤销'
        });          
      });
    },
    // 编辑
    editFun(id){
      this.id = id;
      this.type = 'edit';
      this.dialogVisible = true;
    },
    // 删除
    deleteFun(id){
      this.$confirm('你确认要将删除该公告吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNotice({id}).then(res=>{
          if(res.status == 200){
            this.$message.success('删除成功！')
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    currentChange(val){
      this.page = val;
      this.getData()
    },
    // 状态
    formatterFun(row){
      let str = '';
      if(row.state==1){
        str = '未发布'
      }else if(row.state==0){
        str = '已发布'
      }
      return str
    },
  }
}
</script>
<style lang="scss" scoped>
  .noticeInformationBox{
    .searchBox{
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .searchLeft{
        ul{
          display: flex;
          li{
            display: flex;
            align-items: center;
            margin-right: 30px;

            .searchTitle{
              min-width: 80px;
              text-align: right;
            }
            .searchStatus{
              min-width: 50px;
              text-align: right;
            }
          }
        }
      }

      .searchRight{
        .addBtn{
          background: #bf162a;
          color: #fff;
          border: none;
        }
      }
    }

    .noticeInformationContent{
      margin-top: 20px;
      .signUpFlex{
        display: flex;
        justify-content: center;
        .signUpColor{
          color: rgb(17, 166, 234);
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
  }
</style>